/* GestionForm.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px 0;
}

h1, h2 {
    text-align: center;
    margin: 0;
}

h1 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #511717;
}

h2 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: normal;
    color: #555;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    justify-content: space-between;
}

.form-column {
    width: 48%;
}

label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

select {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    color: #333;
}


input, textarea {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

textarea {
    height: 100px;
    resize: none;
}

.submit-btn {
    background-color: #511717;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
}

.submit-btn:hover {
    background-color: #522b2b;
}

.footer {   
    margin-top: 38px;
    color: #511717;
}

.footer h3 {
    text-align: center;
    font-size: 24px;
}

.footer p{
    margin-top: 38px;
    font-size: 12px;
    text-align: justify;
}

@media (max-width: 768px) {
    .form-group {
        flex-direction: column;
    }

    .form-column {
        width: 100%;
    }
}